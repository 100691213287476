<template>
  <div style="margin: 0 20px;">
    <div id="table-content">
      <table style="width: 900px;height:10%;text-align: center;font-size: 13px;" cellspacing="0">
        <tr>
          <td colspan="2" style="font-size: 20px; height: 40px;padding-left: 10px;letter-spacing:3px;border: 0;">
            烟气自动监测设备日常巡检维护记录
          </td>
        </tr>
        <tr>
          <td colspan="2" style="font-size:16px;height:20px;padding-left:10px;border:0;">
            <p style="text-align: left;margin-left: 20px;">
              <span style="font-size: 14px;">企业名称：{{data.equip.company}}</span>
              <span style="font-size: 14px;float: right;">巡检日期：{{data.equip.date}}</span>
            </p>           
          </td>
        </tr>
      </table>
      <table v-show="oldTable" id="oldTable" border="1" rules="none" cellspacing="0" style="text-align: center;width: 900px;height:1020px;">
        <tbody>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">CEMS设备生产商：{{data.equip.device_name}}</td>
            <td colspan="2">CEMS设备规格型号：{{data.equip.device_model}}</td>
          </tr>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">颗粒物CEMS设备生产商：{{data.equip.device_klw_name}}</td>
            <td colspan="2">颗粒物CEMS设备规格型号：{{data.equip.device_klw_model}}</td>
          </tr>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">安装地点：废气排放口</td>
            <td colspan="2">运维单位：{{data.equip.uphold_name}}</td>
          </tr>
          <tr>
            <td style="width: 200px;">项目</td>
            <td style="width: 200px;">内容</td>
            <td style="width: 100px;">维护情况</td>
            <td style="width: 200px">备注</td>
          </tr>
          <tr>
            <td rowspan="2">维护预备</td>
            <td>查询日志</td>
            <td style="width: 120px;">{{data.table[0].wh_cxrz}}</td>
            <td>{{data.table[0].wh_cxrz_desc}}</td>
          </tr>
          <tr>
            <td>检查材耗</td>
            <td>{{data.table[0].wh_jchc}}</td>
            <td>{{data.table[0].wh_jchc_desc}}</td>
          </tr>
          <tr>
            <td rowspan="7">辅助设备检查</td>
            <td>站房卫生</td>
            <td>{{data.table[0].fzjc_afws}}</td>
            <td>{{data.table[0].fzjc_afws_desc}}</td>
          </tr>
          <tr>
            <td>站房门窗的密封性检查</td>
            <td>{{data.table[0].fzjc_zfmc}}</td>
            <td>{{data.table[0].fzjc_zfmc_desc}}</td>
          </tr>
          <tr>
            <td>供电系统（稳压、UPS等）</td>
            <td>{{data.table[0].fzjc_gdxt}}</td>
            <td>{{data.table[0].fzjc_gdxt_desc}}</td>
          </tr>
          <tr>
            <td>室内温湿度</td>
            <td>{{data.table[0].fzjc_snwd}}</td>
            <td>{{data.table.fzjc_snwd_desc}}</td>
          </tr>
          <tr>
            <td>空调</td>
            <td>{{data.table[0].fzjc_snkt}}</td>
            <td>{{data.table[0].fzjc_snkt_desc}}</td>
          </tr>
          <tr>
            <td>空气压缩机压力</td>
            <td>{{data.table[0].fzjc_kqyl}}</td>
            <td>{{data.table[0].fzjc_kqyl_desc}}</td>
          </tr>
          <tr>
            <td>压缩机排水</td>
            <td>{{data.table[0].fzjc_ysjps}}</td>
            <td>{{data.table[0].fzjc_ysjps_desc}}</td>
          </tr>
          <tr>
            <td rowspan="8">气态污染物监测设备检查</td>
            <td>净化风机检查</td>
            <td>{{data.table[0].qtwyw_jhjjc}}</td>
            <td>{{data.table[0].qtwyw_jhjjc_desc}}</td>
          </tr>
          <tr>
            <td>过滤器及管路检查</td>
            <td>{{data.table[0].qtwyw_glqjc}}</td>
            <td>{{data.table[0].qtwyw_glqjc_desc}}</td>
          </tr>
          <tr>
            <td>标气有效期、钢瓶压力检查</td>
            <td>{{data.table[0].qtwyw_bqyljc}}</td>
            <td>{{data.table[0].qtwyw_bqyljc_desc}}</td>
          </tr>
          <tr>
            <td>测量数据检查</td>
            <td>{{data.table[0].qtwyw_clsjjc}}</td>
            <td>{{data.table[0].qtwyw_clsjjc_desc}}</td>
          </tr>
          <tr>
            <td>分析仪状态</td>
            <td>{{data.table[0].qtwyw_fxyzt}}</td>
            <td>{{data.table[0].qtwyw_fxyzt_desc}}</td>
          </tr>
          <tr>
            <td>测量探头</td>
            <td>{{data.table[0].qtwyw_cltt}}</td>
            <td>{{data.table[0].qtwyw_cltt_desc}}</td>
          </tr>
          <tr>
            <td>分析仪校准</td>
            <td>{{data.table[0].qtwyw_fxyjz}}</td>
            <td>{{data.table[0].qtwyw_fxyjz_desc}}</td>
          </tr>
          <tr>
            <td>系统校验</td>
            <td>{{data.table[0].qtwyw_xtjy}}</td>
            <td>{{data.table[0].qtwyw_xtjy_desc}}</td>
          </tr>
          <tr>
            <td rowspan="4">颗粒物监测设备检查</td>
            <td>检查数据检查</td>
            <td>{{data.table[0].klwjc_sjjc}}</td>
            <td>{{data.table[0].klwjc_sjjc_desc}}</td>
          </tr>
          <tr>
            <td>鼓风机、空气过滤器检查</td>
            <td>{{data.table[0].klwjc_gfjjc}}</td>
            <td>{{data.table[0].klwjc_gfjjc_desc}}</td>
          </tr>
          <tr>
            <td>分析仪的光路检查</td>
            <td>{{data.table[0].klwjc_fxyjc}}</td>
            <td>{{data.table[0].klwjc_fxyjc_desc}}</td>
          </tr>
          <tr>
            <td>校准</td>
            <td>{{data.table[0].klwjc_jz}}</td>
            <td>{{data.table[0].klwjc_jz_desc}}</td>
          </tr>
          <tr>
            <td rowspan="4">流速监测系统检查</td>
            <td>流速、流量、烟道压力测量数据</td>
            <td>{{data.table[0].lsjcjc_lljc}}</td>
            <td>{{data.table[0].lsjcjc_lljc_desc}}</td>
          </tr>
          <tr>
            <td>反吹装置</td>
            <td>{{data.table[0].lsjcjc_fcxt}}</td>
            <td>{{data.table[0].lsjcjc_fcxt_desc}}</td>
          </tr>
          <tr>
            <td>测量传感器</td>
            <td>{{data.table[0].klwjc_clcgq}}</td>
            <td>{{data.table[0].klwjc_clcgq_desc}}</td>
          </tr>
          <tr>
            <td>探头检查</td>
            <td>{{data.table[0].klwjc_ttjc}}</td>
            <td>{{data.table[0].klwjc_ttjc_desc}}</td>
          </tr>
          <tr>
            <td>其他</td>
            <td>氧含量测量数据检查</td>
            <td>{{data.table[0].qt_hyl}}</td>
            <td>{{data.table[0].qt_hyl_desc}}</td>
          </tr>
          <tr>
            <td rowspan="2">数据传输装置</td>
            <td>通信线的连接</td>
            <td>{{data.table[0].sjcs_txlj}}</td>
            <td>{{data.table[0].sjcs_txlj_desc}}</td>
          </tr>
          <tr>
            <td>传输设备电源</td>
            <td>{{data.table[0].sjcs_csdy}}</td>
            <td>{{data.table[0].sjcs_csdy_desc}}</td>
          </tr>
          <tr>
            <td>维护记录</td>
            <td colspan="3">{{data.table[0].remark}}</td>
          </tr>
          <tr>
            <td>运维代表</td>
            <td>{{data.equip.user_name}}</td>
            <td>企业代表</td>
            <td>{{data.equip.charge}}</td>
          </tr>
        </tbody>
      </table>
      <!-- 修改表格 -->
      <table v-show="newTable" id="oldTable" border="1" rules="none" cellspacing="0" style="text-align: center;width: 900px;height:1020px;">
        <tbody>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">CEMS设备生产商：{{data.equip.device_name}}</td>
            <td colspan="2">CEMS设备规格型号：{{data.equip.device_model}}</td>
          </tr>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">颗粒物CEMS设备生产商：{{data.equip.device_klw_name}}</td>
            <td colspan="2">颗粒物CEMS设备规格型号：{{data.equip.device_klw_model}}</td>
          </tr>
          <tr style="text-align: left;">
            <td style="padding: 10px;" colspan="2">安装地点：废气排放口</td>
            <td colspan="2">运维单位：{{data.equip.uphold_name}}</td>
          </tr>
          <tr>
            <td>项目</td>
            <td>内容</td>
            <td>维护情况</td>
            <td style="width: 200px">备注</td>
          </tr>
          <tr>
            <td rowspan="2">维护预备</td>
            <td>查询日志</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].wh_cxrz">正常
              <input type="radio" value="异常" v-model="data.table[0].wh_cxrz">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].wh_cxrz_desc}}</span>
              <input type="text" v-model="data.table[0].wh_cxrz_desc">
            </td>
          </tr>
          <tr>
            <td>检查材耗</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].wh_jchc">正常
              <input type="radio" value="异常" v-model="data.table[0].wh_jchc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].wh_jchc_desc}}</span>
              <input type="text" v-model="data.table[0].wh_jchc_desc">
            </td>
          </tr>
          <tr>
            <td rowspan="7">辅助设备检查</td>
            <td>站房卫生</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_afws">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_afws">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_afws_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_afws_desc">
            </td>
          </tr>
          <tr>
            <td>站房门窗的密封性检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_zfmc">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_zfmc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_zfmc_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_zfmc_desc">
            </td>
          </tr>
          <tr>
            <td>供电系统（稳压、UPS等）</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_gdxt">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_gdxt">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_gdxt_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_gdxt_desc">
            </td>
          </tr>
          <tr>
            <td>室内温湿度</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_snwd">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_snwd">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_snwd_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_snwd_desc">
            </td>
          </tr>
          <tr>
            <td>空调</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_snkt">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_snkt">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_snkt_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_snkt_desc">
            </td>
          </tr>
          <tr>
            <td>空气压缩机压力</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_kqyl">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_kqyl">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_kqyl_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_kqyl_desc">
            </td>
          </tr>
          <tr>
            <td>压缩机排水</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].fzjc_ysjps">正常
              <input type="radio" value="异常" v-model="data.table[0].fzjc_ysjps">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].fzjc_ysjps_desc}}</span>
              <input type="text" v-model="data.table[0].fzjc_ysjps_desc">
            </td>
          </tr>
          <tr>
            <td rowspan="8">气态污染物监测设备检查</td>
            <td>净化风机检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_jhjjc">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_jhjjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_jhjjc_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_jhjjc_desc">
            </td>
          </tr>
          <tr>
            <td>过滤器及管路检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_glqjc">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_glqjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_glqjc_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_glqjc_desc">
            </td>
          </tr>
          <tr>
            <td>标气有效期、钢瓶压力检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_bqyljc">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_bqyljc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_bqyljc_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_bqyljc_desc">
            </td>
          </tr>
          <tr>
            <td>测量数据检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_clsjjc">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_clsjjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_clsjjc_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_clsjjc_desc">
            </td>
          </tr>
          <tr>
            <td>分析仪状态</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_fxyzt">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_fxyzt">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_fxyzt_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_fxyzt_desc">
            </td>
          </tr>
          <tr>
            <td>测量探头</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_cltt">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_cltt">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_cltt_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_cltt_desc">
            </td>
          </tr>
          <tr>
            <td>分析仪校准</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_fxyjz">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_fxyjz">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_fxyjz_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_fxyjz_desc">
            </td>
          </tr>
          <tr>
            <td>系统校验</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qtwyw_xtjy">正常
              <input type="radio" value="异常" v-model="data.table[0].qtwyw_xtjy">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qtwyw_xtjy_desc}}</span>
              <input type="text" v-model="data.table[0].qtwyw_xtjy_desc">
            </td>
          </tr>
          <tr>
            <td rowspan="4">颗粒物监测设备检查</td>
            <td>检查数据检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_sjjc">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_sjjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_sjjc_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_sjjc_desc">
            </td>
          </tr>
          <tr>
            <td>鼓风机、空气过滤器检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_gfjjc">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_gfjjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_gfjjc_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_gfjjc_desc">
            </td>
          </tr>
          <tr>
            <td>分析仪的光路检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_fxyjc">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_fxyjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_fxyjc_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_fxyjc_desc">
            </td>
          </tr>
          <tr>
            <td>校准</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_jz">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_jz">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_jz_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_jz_desc">
            </td>
          </tr>
          <tr>
            <td rowspan="4">流速监测系统检查</td>
            <td>流速、流量、烟道压力测量数据</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].lsjcjc_lljc">正常
              <input type="radio" value="异常" v-model="data.table[0].lsjcjc_lljc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].lsjcjc_lljc_desc}}</span>
              <input type="text" v-model="data.table[0].lsjcjc_lljc_desc">
            </td>
          </tr>
          <tr>
            <td>反吹装置</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].lsjcjc_fcxt">正常
              <input type="radio" value="异常" v-model="data.table[0].lsjcjc_fcxt">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].lsjcjc_fcxt_desc}}</span>
              <input type="text" v-model="data.table[0].lsjcjc_fcxt_desc">
            </td>
          </tr>
          <tr>
            <td>测量传感器</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_clcgq">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_clcgq">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_clcgq_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_clcgq_desc">
            </td>
          </tr>
          <tr>
            <td>探头检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].klwjc_ttjc">正常
              <input type="radio" value="异常" v-model="data.table[0].klwjc_ttjc">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].klwjc_ttjc_desc}}</span>
              <input type="text" v-model="data.table[0].klwjc_ttjc_desc">
            </td>
          </tr>
          <tr>
            <td>其他</td>
            <td>氧含量测量数据检查</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].qt_hyl">正常
              <input type="radio" value="异常" v-model="data.table[0].qt_hyl">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].qt_hyl_desc}}</span>
              <input type="text" v-model="data.table[0].qt_hyl_desc">
            </td>
          </tr>
          <tr>
            <td rowspan="2">数据传输装置</td>
            <td>通信线的连接</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].sjcs_txlj">正常
              <input type="radio" value="异常" v-model="data.table[0].sjcs_txlj">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].sjcs_txlj_desc}}</span>
              <input type="text" v-model="data.table[0].sjcs_txlj_desc">
            </td>
          </tr>
          <tr>
            <td>传输设备电源</td>
            <td>
              <input type="radio" value="正常" v-model="data.table[0].sjcs_csdy">正常
              <input type="radio" value="异常" v-model="data.table[0].sjcs_csdy">异常
            </td>
            <td class="td_cell">
              <span>{{data.table[0].sjcs_csdy_desc}}</span>
              <input type="text" v-model="data.table[0].sjcs_csdy_desc">
            </td>
          </tr>
          <tr>
            <td>维护记录</td>
            <td colspan="3" class="td_cell">
              <span>{{data.table[0].remark}}</span>
              <input type="text" v-model="data.table[0].remark" style="width:99.2%;">
            </td>
          </tr>
          <tr>
            <td>运维代表</td>
            <td>{{data.equip.user_name}}</td>
            <td>企业代表</td>
            <td>{{data.equip.charge}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="block">
      <template v-if="!read">
        <el-button type="primary" @click="createNewTable">修改表格</el-button>
        <el-button type="primary" @click="updateTable">保存修改</el-button>
      </template>
      <el-button type="primary" v-print="printTable">导出pdf</el-button>
      <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
    </div>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
let tableExport = require('table-export');
export default {
  props: ['data','name','read'],
  data() {
    return {
      oldTable: true,
      newTable: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback (vue) {
          $('title').text('CEMS日常巡检记录表')
        },
        closeCallback (vue) {
          $('title').text('记录详情')
        }
      }
    }
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data))
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      this.$emit('updateTable', this.data.table, val => {
        if (val === 'error') {
          this.data = this.saveData
        }
      })
    },
    // wordExport() {
    //   let data = {
    //     fileName: "测试文档",//文档名
    //     fileType: ".doc",//文档类型 doc xls html 其他的自己去试
    //     isHeader: false,//是否显示页眉
    //     isFooter: false,//是否显示页脚
    //     header: "",//页眉标题
    //     styles: $("#wordExPortCss"),//要导出的内容style
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式  宽度高度 margin之类的
    //   }
    //   $('#table-content').wordExport(this.name,data);
    // }
  },
}
</script>
<style lang="less" scoped id="wordExPortCss">
td {
  border: 1px solid;
  padding: 5px;
}
input{
  width: 10px;
  height: 10px;
}
.block{
  margin: 20px;
}
.td_cell{
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 93%;
  caret-color: #426BF2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 210mm 297mm;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>