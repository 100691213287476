<template>
  <div>
    <el-row :gutter="10" style="padding-left:15px;">
      <el-col v-for="(item, index) in data.images" :key="index" :md="8" :lg="6" :xl="4">
        <el-image :src="config.storage + item.url" :alt="index" fit="cover" style="width: 100%;height: 150px;margin-top:10px;" :preview-src-list="data.imagelist"></el-image>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ['data'],
  data() {
    return {
      dialogVisible: false,
      imgUrl: ''
    }
  },
  computed: mapState({
    url: (state) => state.url,
    config: (state) => state.config,
  }),
  methods: {
    ...mapActions(["pageInit"]),
  }
}
</script>
<style lang="less" scoped>
/deep/ .bottom {
  margin-bottom: 5vh;
}
/deep/ .el-dialog__body {
  flex-direction: initial;
}
</style>