<template>
  <div style="margin: 0 20px;">
    <div id="table-content">
      <table style="width: 850px;text-align: center;font-size: 13px;" cellspacing="0">
        <tr>
          <td colspan="2" style="font-size: 20px;padding-left: 10px;letter-spacing:3px;border: 0;">
            烟气自动监测设备零漂、跨漂校准记录表
          </td>
        </tr>
        <tr>
          <td colspan="2" style="padding-left:10px;border:0;">
            <p style="text-align: left;margin-left: 30px;">企业名称：{{data.equip.company}}</p>           
          </td>
        </tr>
      </table>
      <table v-show="oldTable" id="oldTable" border="1" rules="none" cellspacing="0" style="text-align: center;width: 850px;height:1020px;">
        <tbody>
          <tr>
            <td>设备名称</td>
            <td colspan="2">{{data.equip.device_name}}</td>
            <td>规格型号</td>
            <td colspan="2">{{data.equip.device_model}}</td>
          </tr>
          <tr>
            <td>运维单位</td>
            <td colspan="2">{{data.equip.uphold_name}}</td>
            <td>校准时间</td>
            <td colspan="2">{{data.equip.date}}</td>
          </tr>
          <tr>
            <td colspan="6">SO2分析仪校准：</td>
          </tr>
          <tr>
            <td>分析仪原理</td>
            <td>{{data.table[0].so2_principle}}</td>
            <td>分析仪量程</td>
            <td>{{data.table[0].so2_range}}</td>
            <td>计量单位</td>
            <td>{{data.table[0].so2_unit}}</td>
          </tr>
          <tr>
            <td rowspan="2">零点漂移校准</td>
            <td>零气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].so2_zero_value}}</td>
            <td>{{data.table[0].so2_zero_bef_value}}</td>
            <td>{{data.table[0].so2_zero_rate}}</td>
            <td>{{data.table[0].so2_zero_result}}</td>
            <td>{{data.table[0].so2_zero_aft_value}}</td>
          </tr>
          <tr>
            <td rowspan="2">跨度漂移校准</td>
            <td>标气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].so2_span_value}}</td>
            <td>{{data.table[0].so2_span_bef_value}}</td>
            <td>{{data.table[0].so2_span_rate}}</td>
            <td>{{data.table[0].so2_span_result}}</td>
            <td>{{data.table[0].so2_span_aft_value}}</td>
          </tr>
          <tr>
            <td colspan="6">NOx分析仪校准：</td>
          </tr>
          <tr>
            <td>分析仪原理</td>
            <td>{{data.table[0].no_principle}}</td>
            <td>分析仪量程</td>
            <td>{{data.table[0].no_range}}</td>
            <td>计量单位</td>
            <td>{{data.table[0].no_unit}}</td>
          </tr>
          <tr>
            <td rowspan="2">零点漂移校准</td>
            <td>零气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].no_zero_value}}</td>
            <td>{{data.table[0].no_zero_bef_value}}</td>
            <td>{{data.table[0].no_zero_rate}}</td>
            <td>{{data.table[0].no_zero_result}}</td>
            <td>{{data.table[0].no_zero_aft_value}}</td>
          </tr>
          <tr>
            <td rowspan="2">跨度漂移校准</td>
            <td>标气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].no_span_value}}</td>
            <td>{{data.table[0].no_span_bef_value}}</td>
            <td>{{data.table[0].no_span_rate}}</td>
            <td>{{data.table[0].no_span_result}}</td>
            <td>{{data.table[0].no_span_aft_value}}</td>
          </tr>
          <tr>
            <td colspan="6">O2分析仪校准：</td>
          </tr>
          <tr>
            <td>分析仪原理</td>
            <td>{{data.table[0].o_principle}}</td>
            <td>分析仪量程</td>
            <td>{{data.table[0].o_range}}</td>
            <td>计量单位</td>
            <td>{{data.table[0].o_unit}}</td>
          </tr>
          <tr>
            <td rowspan="2">零点漂移校准</td>
            <td>零气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].o_zero_value}}</td>
            <td>{{data.table[0].o_zero_bef_value}}</td>
            <td>{{data.table[0].o_zero_rate}}</td>
            <td>{{data.table[0].o_zero_result}}</td>
            <td>{{data.table[0].o_zero_aft_value}}</td>
          </tr>
          <tr>
            <td rowspan="2">跨度漂移校准</td>
            <td>标气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].o_span_value}}</td>
            <td>{{data.table[0].o_span_bef_value}}</td>
            <td>{{data.table[0].o_span_rate}}</td>
            <td>{{data.table[0].o_span_result}}</td>
            <td>{{data.table[0].o_span_aft_value}}</td>
          </tr>
          <tr>
            <td colspan="6">流速仪校准：</td>
          </tr>
          <tr>
            <td>分析仪原理</td>
            <td>{{data.table[0].ls_principle}}</td>
            <td>分析仪量程</td>
            <td>{{data.table[0].ls_range}}</td>
            <td>计量单位</td>
            <td>{{data.table[0].ls_unit}}</td>
          </tr>
          <tr>
            <td rowspan="2">零点漂移校准</td>
            <td>零气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].ls_zero_value}}</td>
            <td>{{data.table[0].ls_zero_bef_value}}</td>
            <td>{{data.table[0].ls_zero_rate}}</td>
            <td>{{data.table[0].ls_zero_result}}</td>
            <td>{{data.table[0].ls_zero_aft_value}}</td>
          </tr>
          <tr>
            <td rowspan="2">跨度漂移校准</td>
            <td>标气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].ls_span_value}}</td>
            <td>{{data.table[0].ls_span_bef_value}}</td>
            <td>{{data.table[0].ls_span_rate}}</td>
            <td>{{data.table[0].ls_span_result}}</td>
            <td>{{data.table[0].ls_span_aft_value}}</td>
          </tr>
          <tr>
            <td colspan="6">烟尘仪校准：</td>
          </tr>
          <tr>
            <td>分析仪原理</td>
            <td>{{data.table[0].yc_principle}}</td>
            <td>分析仪量程</td>
            <td>{{data.table[0].yc_range}}</td>
            <td>计量单位</td>
            <td>{{data.table[0].yc_unit}}</td>
          </tr>
          <tr>
            <td rowspan="2">零点漂移校准</td>
            <td>零气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].yc_zero_value}}</td>
            <td>{{data.table[0].yc_zero_bef_value}}</td>
            <td>{{data.table[0].yc_zero_rate}}</td>
            <td>{{data.table[0].yc_zero_result}}</td>
            <td>{{data.table[0].yc_zero_aft_value}}</td>
          </tr>
          <tr>
            <td rowspan="2">跨度漂移校准</td>
            <td>标气浓度值</td>
            <td>校准前测试值</td>
            <td>零点漂移%</td>
            <td>仪器校准是否正常</td>
            <td>校准后测试值</td>
          </tr>
          <tr>
            <td>{{data.table[0].yc_span_value}}</td>
            <td>{{data.table[0].yc_span_bef_value}}</td>
            <td>{{data.table[0].yc_span_rate}}</td>
            <td>{{data.table[0].yc_span_result}}</td>
            <td>{{data.table[0].yc_span_aft_value}}</td>
          </tr>
          <tr>
            <td>运维代表</td>
            <td colspan="2">{{data.equip.user_name}}</td>
            <td>企业代表</td>
            <td colspan="2">{{data.equip.charge}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 修改表格 -->
    <table v-show="newTable" border="1" rules="none" cellspacing="0" style="text-align: center;width: 850px;height:1020px;">
      <tbody>
        <tr>
            <td>设备名称</td>
            <td colspan="2">{{data.equip.device_name}}</td>
            <td>规格型号</td>
            <td colspan="2">{{data.equip.device_model}}</td>
          </tr>
          <tr>
            <td>运维单位</td>
            <td colspan="2">{{data.equip.uphold_name}}</td>
            <td>校准时间</td>
            <td colspan="2">{{data.equip.date}}</td>
          </tr>
        <tr>
          <td colspan="7" style="text-align: center;">SO2分析仪校准：</td>
        </tr>
        <tr>
          <td>分析仪原理</td>
          <td class="td_cell">
            <span>{{data.table[0].so2_principle}}</span>
            <input type="text" v-model="data.table[0].so2_principle">
          </td>
          <td>分析仪量程</td>
          <td class="td_cell">
            <span>{{data.table[0].so2_range}}</span>
            <input type="text" v-model="data.table[0].so2_range">
          </td>
          <td>计量单位</td>
          <td class="td_cell">
            <span>{{data.table[0].so2_unit}}</span>
            <input type="text" v-model="data.table[0].so2_unit" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">零点漂移校准</td>
          <td>零气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].so2_zero_value}}</span>
            <input type="text" v-model="data.table[0].so2_zero_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_zero_bef_value}}</span>
            <input type="text" v-model="data.table[0].so2_zero_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_zero_rate}}</span>
            <input type="text" v-model="data.table[0].so2_zero_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_zero_result}}</span>
            <input type="text" v-model="data.table[0].so2_zero_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_zero_aft_value}}</span>
            <input type="text" v-model="data.table[0].so2_zero_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">跨度漂移校准</td>
          <td>标气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].so2_span_value}}</span>
            <input type="text" v-model="data.table[0].so2_span_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_span_bef_value}}</span>
            <input type="text" v-model="data.table[0].so2_span_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_span_rate}}</span>
            <input type="text" v-model="data.table[0].so2_span_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_span_result}}</span>
            <input type="text" v-model="data.table[0].so2_span_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].so2_span_aft_value}}</span>
            <input type="text" v-model="data.table[0].so2_span_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td colspan="7" style="text-align: center;">NOx分析仪校准：</td>
        </tr>
        <tr>
          <td>分析仪原理</td>
          <td class="td_cell">
            <span>{{data.table[0].no_principle}}</span>
            <input type="text" v-model="data.table[0].no_principle">
          </td>
          <td>分析仪量程</td>
          <td class="td_cell">
            <span>{{data.table[0].no_range}}</span>
            <input type="text" v-model="data.table[0].no_range">
          </td>
          <td>计量单位</td>
          <td class="td_cell">
            <span>{{data.table[0].no_unit}}</span>
            <input type="text" v-model="data.table[0].no_unit" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">零点漂移校准</td>
          <td>零气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].no_zero_value}}</span>
            <input type="text" v-model="data.table[0].no_zero_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_zero_bef_value}}</span>
            <input type="text" v-model="data.table[0].no_zero_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_zero_rate}}</span>
            <input type="text" v-model="data.table[0].no_zero_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_zero_result}}</span>
            <input type="text" v-model="data.table[0].no_zero_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_zero_aft_value}}</span>
            <input type="text" v-model="data.table[0].no_zero_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">跨度漂移校准</td>
          <td>标气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].no_span_value}}</span>
            <input type="text" v-model="data.table[0].no_span_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_span_bef_value}}</span>
            <input type="text" v-model="data.table[0].no_span_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_span_rate}}</span>
            <input type="text" v-model="data.table[0].no_span_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_span_result}}</span>
            <input type="text" v-model="data.table[0].no_span_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].no_span_aft_value}}</span>
            <input type="text" v-model="data.table[0].no_span_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td colspan="7" style="text-align: center;">O2分析仪校准：</td>
        </tr>
        <tr>
          <td>分析仪原理</td>
          <td class="td_cell">
            <span>{{data.table[0].o_principle}}</span>
            <input type="text" v-model="data.table[0].o_principle">
          </td>
          <td>分析仪量程</td>
          <td class="td_cell">
            <span>{{data.table[0].o_range}}</span>
            <input type="text" v-model="data.table[0].o_range">
          </td>
          <td>计量单位</td>
          <td class="td_cell">
            <span>{{data.table[0].o_unit}}</span>
            <input type="text" v-model="data.table[0].o_unit" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">零点漂移校准</td>
          <td>零气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].o_zero_value}}</span>
            <input type="text" v-model="data.table[0].o_zero_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_zero_bef_value}}</span>
            <input type="text" v-model="data.table[0].o_zero_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_zero_rate}}</span>
            <input type="text" v-model="data.table[0].o_zero_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_zero_result}}</span>
            <input type="text" v-model="data.table[0].o_zero_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_zero_aft_value}}</span>
            <input type="text" v-model="data.table[0].o_zero_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">跨度漂移校准</td>
          <td>标气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].o_span_value}}</span>
            <input type="text" v-model="data.table[0].o_span_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_span_bef_value}}</span>
            <input type="text" v-model="data.table[0].o_span_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_span_rate}}</span>
            <input type="text" v-model="data.table[0].o_span_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_span_result}}</span>
            <input type="text" v-model="data.table[0].o_span_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].o_span_aft_value}}</span>
            <input type="text" v-model="data.table[0].o_span_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td colspan="7" style="text-align: center;">流速仪校准：</td>
        </tr>
        <tr>
          <td>分析仪原理</td>
          <td class="td_cell">
            <span>{{data.table[0].ls_principle}}</span>
            <input type="text" v-model="data.table[0].ls_principle">
          </td>
          <td>分析仪量程</td>
          <td class="td_cell">
            <span>{{data.table[0].ls_range}}</span>
            <input type="text" v-model="data.table[0].ls_range">
          </td>
          <td>计量单位</td>
          <td class="td_cell">
            <span>{{data.table[0].ls_unit}}</span>
            <input type="text" v-model="data.table[0].ls_unit" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">零点漂移校准</td>
          <td>零气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].ls_zero_value}}</span>
            <input type="text" v-model="data.table[0].ls_zero_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_zero_bef_value}}</span>
            <input type="text" v-model="data.table[0].ls_zero_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_zero_rate}}</span>
            <input type="text" v-model="data.table[0].ls_zero_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_zero_result}}</span>
            <input type="text" v-model="data.table[0].ls_zero_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_zero_aft_value}}</span>
            <input type="text" v-model="data.table[0].ls_zero_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">跨度漂移校准</td>
          <td>标气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].ls_span_value}}</span>
            <input type="text" v-model="data.table[0].ls_span_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_span_bef_value}}</span>
            <input type="text" v-model="data.table[0].ls_span_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_span_rate}}</span>
            <input type="text" v-model="data.table[0].ls_span_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_span_result}}</span>
            <input type="text" v-model="data.table[0].ls_span_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].ls_span_aft_value}}</span>
            <input type="text" v-model="data.table[0].ls_span_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td colspan="7" style="text-align: center;">烟尘仪校准：</td>
        </tr>
        <tr>
          <td>分析仪原理</td>
          <td class="td_cell">
            <span>{{data.table[0].yc_principle}}</span>
            <input type="text" v-model="data.table[0].yc_principle">
          </td>
          <td>分析仪量程</td>
          <td class="td_cell">
            <span>{{data.table[0].yc_range}}</span>
            <input type="text" v-model="data.table[0].yc_range">
          </td>
          <td>计量单位</td>
          <td class="td_cell">
            <span>{{data.table[0].yc_unit}}</span>
            <input type="text" v-model="data.table[0].yc_unit" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">零点漂移校准</td>
          <td>零气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].yc_zero_value}}</span>
            <input type="text" v-model="data.table[0].yc_zero_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_zero_bef_value}}</span>
            <input type="text" v-model="data.table[0].yc_zero_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_zero_rate}}</span>
            <input type="text" v-model="data.table[0].yc_zero_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_zero_result}}</span>
            <input type="text" v-model="data.table[0].yc_zero_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_zero_aft_value}}</span>
            <input type="text" v-model="data.table[0].yc_zero_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
          <td rowspan="2">跨度漂移校准</td>
          <td>标气浓度值</td>
          <td>校准前测试值</td>
          <td>零点漂移%</td>
          <td>仪器校准是否正常</td>
          <td>校准后测试值</td>
        </tr>
        <tr>
          <td class="td_cell">
            <span>{{data.table[0].yc_span_value}}</span>
            <input type="text" v-model="data.table[0].yc_span_value">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_span_bef_value}}</span>
            <input type="text" v-model="data.table[0].yc_span_bef_value" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_span_rate}}</span>
            <input type="text" v-model="data.table[0].yc_span_rate">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_span_result}}</span>
            <input type="text" v-model="data.table[0].yc_span_result" style="width:97%;">
          </td>
          <td class="td_cell">
            <span>{{data.table[0].yc_span_aft_value}}</span>
            <input type="text" v-model="data.table[0].yc_span_aft_value" style="width:97%;">
          </td>
        </tr>
        <tr>
            <td>运维代表</td>
            <td colspan="2">{{data.equip.user_name}}</td>
            <td>企业代表</td>
            <td colspan="2">{{data.equip.charge}}</td>
          </tr>
      </tbody>
    </table>
    <div class="block">
      <template v-if="!read">
        <el-button type="primary" @click="createNewTable">修改表格</el-button>
        <el-button type="primary" @click="updateTable">保存修改</el-button>
      </template>
      <el-button type="primary" v-print="printTable">导出pdf</el-button>
      <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
    </div>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
export default {
  props: ['data','name','read'],
  data() {
    return {
      oldTable: true,
      newTable: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback (vue) {
          $('title').text('烟气校准记录表')
        },
        closeCallback (vue) {
          $('title').text('记录详情')
        }
      }
    }
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data))
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      this.$emit('updateTable', this.data.table, val => {
        if (val === 'error') {
          this.data = this.saveData
        }
      })
    },
    // wordExport() {
    //   let data = {
    //     fileName: "",                         //文档名
    //     fileType: ".doc",                     //文档类型
    //     isHeader: false,                      //是否显示页眉
    //     isFooter: false,                      //是否显示页脚
    //     header: "",                           //页眉标题
    //     styles: $("#wordExPortCss"),          //要导出的内容样式
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式
    //   }
    //   $('#table-content').wordExport(this.name,data);
    // }
  },
}
</script>
<style lang="less" scoped id="wordExPortCss">
td {
  border: 1px solid;
  padding: 5px;
  height: 22px;
}
.block{
  margin: 20px;
}
.td_cell{
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 96%;
  height: 90%;
  caret-color: #426BF2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 850px 1020px;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>