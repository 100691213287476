<template>
  <el-row :gutter="10" style="padding-left:15px;">
    <el-col v-for="(item, index) in data" :key="index" :md="12" :lg="8" :xl="6">
      <video :src="config.storage + item.video_path" controls style="width: 100%;height: 250px;margin-left:10px;"></video>
    </el-col>
  </el-row>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ['data'],
  computed: mapState({
    url: (state) => state.url,
    config: (state) => state.config,
  }),
}
</script>