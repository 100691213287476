<template>
  <div style="margin: 0 20px;" v-if="status">
    <el-tabs v-model="activeName">
      <el-tab-pane
        v-for="(item, index) in data.table"
        :key="index"
        :label="'表' + (index + 1)"
        :name="'表' + (index + 1)"
      >
        <div :id="'table-content' + (index + 1)">
          <table
            style="width: 800px;height:10%;text-align: center;font-size: 13px;"
            cellspacing="0"
          >
            <tr>
              <td
                colspan="2"
                style="font-size: 20px; height: 40px;padding-left: 10px;letter-spacing:3px;border: 0;"
              >
                设备故障及处理记录表
              </td>
            </tr>
            <tr>
              <td
                colspan="2"
                style="font-size:16px;height:20px;padding:10px;border:0;text-align: left;"
              >
                <span style="margin-left: 20px;"
                  >设备名称：{{ data.equip.device_name }}</span
                >
                <span style="margin-left: 200px;"
                  >设备型号：{{ data.equip.device_model }}</span
                >
              </td>
            </tr>
          </table>

          <table
            v-show="oldTable"
            id="oldTable"
            border="1"
            rules="none"
            cellspacing="0"
            style="text-align: center;width: 800px;height:1020px;"
          >
            <tbody>
              <tr>
                <td>故障时间</td>
                <td>故障设备或因子</td>
                <td>故障信息</td>
                <td>处理方法</td>
                <td>恢复时间</td>
                <td>运维代表</td>
                <td>企业代表</td>
              </tr>
              <tr v-for="(item, i) in 22" :key="i">
                <td>{{ data.table[index][i].fault_time }}</td>
                <td>{{ data.table[index][i].fault_device }}</td>
                <td>{{ data.table[index][i].fault_info }}</td>
                <td>{{ data.table[index][i].fault_handle }}</td>
                <td>{{ data.table[index][i].fault_rec_time }}</td>
                <td>{{ data.table[index][i].user_name }}</td>
                <td>{{ data.table[index][i].charge }}</td>
              </tr>
            </tbody>
          </table>
          <!-- 修改表格 -->
          <table
            v-show="newTable"
            border="1"
            rules="none"
            cellspacing="0"
            style="text-align: center;width: 800px;height:1020px;"
          >
            <tbody>
              <tr>
                <td>故障时间</td>
                <td>故障设备或因子</td>
                <td>故障信息</td>
                <td>处理方法</td>
                <td>恢复时间</td>
                <td>运维代表</td>
                <td>企业代表</td>
              </tr>
              <tr v-for="(item, i) in 22" :key="i">
                <td class="td_cell">
                  <span>{{ data.table[index][i].fault_time }}</span>
                  <input
                    type="text"
                    v-model="data.table[index][i].fault_time"
                  />
                </td>
                <td class="td_cell">
                  <span>{{ data.table[index][i].fault_device }}</span>
                  <input
                    type="text"
                    v-model="data.table[index][i].fault_device"
                  />
                </td>
                <td class="td_cell">
                  <span>{{ data.table[index][i].fault_info }}</span>
                  <input
                    type="text"
                    v-model="data.table[index][i].fault_info"
                    style="width:95%"
                  />
                </td>
                <td class="td_cell">
                  <span>{{ data.table[index][i].fault_handle }}</span>
                  <input
                    type="text"
                    v-model="data.table[index][i].fault_handle"
                    style="width:95%"
                  />
                </td>
                <td class="td_cell">
                  <span>{{ data.table[index][i].fault_rec_time }}</span>
                  <input
                    type="text"
                    v-model="data.table[index][i].fault_rec_time"
                  />
                </td>
                <td>{{ data.table[index][i].user_name }}</td>
                <td>{{ data.table[index][i].charge }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="block">
          <template v-if="!read">
            <el-button type="primary" @click="createNewTable"
              >修改表格</el-button
            >
            <el-button type="primary" @click="updateTable">保存修改</el-button>
          </template>
          <el-button
            type="primary"
            @click="printTable.id = 'table-content' + (index + 1)"
            v-print="printTable"
            >导出pdf</el-button
          >
          <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
export default {
  props: ["data", "name", "read"],
  data() {
    return {
      activeName: "表1",
      oldTable: true,
      newTable: false,
      sum: 0,
      status: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback(vue) {
          $("title").text("设备故障及处理记录表");
        },
        closeCallback(vue) {
          $("title").text("记录详情");
        }
      }
    };
  },
  mounted() {
    //数据处理
    this.sum = this.data.table.length;
    for (let i = 0; i < this.data.table.length; i++) {
      this.data.table[i].user_name = this.data.equip.user_name;
      this.data.table[i].charge = this.data.equip.charge;
    }
    let rem = 22 - (this.data.table.length % 22);
    for (let i = 0; i < rem; i++) {
      const obj = {
        fault_device: "",
        fault_handle: "",
        fault_info: "",
        fault_rec_time: "",
        fault_time: "-"
      };
      this.data.table.push(obj);
    }
    let a = [];
    let num = this.data.table.length / 22;
    for (let j = 0; j < num; j++) {
      let b = [];
      for (let i = 0; i < 22; i++) {
        b[i] = this.data.table[j * 22 + i];
      }
      a[j] = b;
    }
    this.data.table = a;
    this.status = true;
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data));
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      //新开辟一个内存地址，存储返回给后端的数据
      let tableList = JSON.parse(JSON.stringify(this.data.table));
      for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < tableList[i].length; j++) {
          delete tableList[i][j].user_name;
          delete tableList[i][j].charge;
        }
      }
      let arr = [];
      for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < tableList[i].length; j++) {
          arr.push(tableList[i][j]);
        }
      }
      let data = arr.slice(0, this.sum);
      this.$emit("updateTable", data, val => {
        if (val === "error") {
          this.data = this.saveData;
        }
      });
    }
    // wordExport() {
    //   let data = {
    //     fileName: "",                         //文档名
    //     fileType: ".doc",                     //文档类型
    //     isHeader: false,                      //是否显示页眉
    //     isFooter: false,                      //是否显示页脚
    //     header: "",                           //页眉标题
    //     styles: $("#wordExPortCss"),          //要导出的内容样式
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式
    //   }
    //   $('#table-content').wordExport(this.name,data);
    // }
  }
};
</script>
<style lang="less" scoped id="wordExPortCss">
td {
  border: 1px solid;
  padding: 5px;
  height: 22px;
}
.block {
  margin: 20px;
}
.td_cell {
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 95%;
  caret-color: #426bf2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 210mm 297mm;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>
