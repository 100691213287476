<template>
  <div class="box-content" v-if="status">
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="(item, index) in data.table" :key="index" :label="'表' + (index + 1)" :name="'表' + (index + 1)">
        <div :id="'table-content' + (index + 1)">
          <table style="width: 800px;height:10%;text-align: center;font-size: 13px;" cellspacing="0">
            <tr>
              <td colspan="2" style="font-size: 20px; height: 40px;padding-left: 10px;letter-spacing:3px;border: 0;">
              实际水样比对试验结果记录表
              </td>
            </tr>
          </table>
          <table v-show="oldTable" :id="'table' + (index + 1)" border="1" rules="none" cellspacing="0" style="text-align: center;width: 800px;height:1020px;">
            <tbody>
              <tr>
                <td>运维代表</td>
                <td>{{data.equip.user_name}}</td>
                <td>企业代表</td>
                <td>{{data.equip.charge}}</td>
                <td>日期：{{data.equip.date}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][0].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][0].specimen_value}}</td>
                <td>{{data.table[index][0].specimen_contrast_value}}</td>
                <td>{{data.table[index][0].specimen_error}}</td>
                <td>{{data.table[index][0].specimen_result}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][0].water_value_1}}</td>
                <td>{{data.table[index][0].water_contrast_value_1}}</td>
                <td>{{data.table[index][0].water_error_1}}</td>
                <td>{{data.table[index][0].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][0].water_value_2}}</td>
                <td>{{data.table[index][0].water_contrast_value_2}}</td>
                <td>{{data.table[index][0].water_error_2}}</td>
                <td>{{data.table[index][0].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][0].water_value_3}}</td>
                <td>{{data.table[index][0].water_contrast_value_3}}</td>
                <td>{{data.table[index][0].water_error_3}}</td>
                <td>{{data.table[index][0].water_result_3}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][1].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][1].specimen_value}}</td>
                <td>{{data.table[index][1].specimen_contrast_value}}</td>
                <td>{{data.table[index][1].specimen_result}}</td>
                <td>{{data.table[index][1].specimen_error}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][1].water_value_1}}</td>
                <td>{{data.table[index][1].water_contrast_value_1}}</td>
                <td>{{data.table[index][1].water_error_1}}</td>
                <td>{{data.table[index][1].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][1].water_value_2}}</td>
                <td>{{data.table[index][1].water_contrast_value_2}}</td>
                <td>{{data.table[index][1].water_error_2}}</td>
                <td>{{data.table[index][1].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][1].water_value_3}}</td>
                <td>{{data.table[index][1].water_contrast_value_3}}</td>
                <td>{{data.table[index][1].water_error_3}}</td>
                <td>{{data.table[index][1].water_result_3}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][2].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][2].specimen_value}}</td>
                <td>{{data.table[index][2].specimen_contrast_value}}</td>
                <td>{{data.table[index][2].specimen_error}}</td>
                <td>{{data.table[index][2].specimen_result}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][2].water_value_1}}</td>
                <td>{{data.table[index][2].water_contrast_value_1}}</td>
                <td>{{data.table[index][2].water_error_1}}</td>
                <td>{{data.table[index][2].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][2].water_value_2}}</td>
                <td>{{data.table[index][2].water_contrast_value_2}}</td>
                <td>{{data.table[index][2].water_error_2}}</td>
                <td>{{data.table[index][2].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][2].water_value_3}}</td>
                <td>{{data.table[index][2].water_contrast_value_3}}</td>
                <td>{{data.table[index][2].water_error_3}}</td>
                <td>{{data.table[index][2].water_result_3}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][3].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][3].specimen_value}}</td>
                <td>{{data.table[index][3].specimen_contrast_value}}</td>
                <td>{{data.table[index][3].specimen_error}}</td>
                <td>{{data.table[index][3].specimen_result}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][3].water_value_1}}</td>
                <td>{{data.table[index][3].water_contrast_value_1}}</td>
                <td>{{data.table[index][3].water_error_1}}</td>
                <td>{{data.table[index][3].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][3].water_value_2}}</td>
                <td>{{data.table[index][3].water_contrast_value_2}}</td>
                <td>{{data.table[index][3].water_error_2}}</td>
                <td>{{data.table[index][3].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][3].water_value_3}}</td>
                <td>{{data.table[index][3].water_contrast_value_3}}</td>
                <td>{{data.table[index][3].water_error_3}}</td>
                <td>{{data.table[index][3].water_result_3}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][4].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][4].specimen_value}}</td>
                <td>{{data.table[index][4].specimen_contrast_value}}</td>
                <td>{{data.table[index][4].specimen_error}}</td>
                <td>{{data.table[index][4].specimen_result}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][4].water_value_1}}</td>
                <td>{{data.table[index][4].water_contrast_value_1}}</td>
                <td>{{data.table[index][4].water_error_1}}</td>
                <td>{{data.table[index][4].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][4].water_value_2}}</td>
                <td>{{data.table[index][4].water_contrast_value_2}}</td>
                <td>{{data.table[index][4].water_error_2}}</td>
                <td>{{data.table[index][4].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][4].water_value_3}}</td>
                <td>{{data.table[index][4].water_contrast_value_3}}</td>
                <td>{{data.table[index][4].water_error_3}}</td>
                <td>{{data.table[index][4].water_result_3}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td>{{data.table[index][5].device_name}}</td>
              </tr>
              <tr>
                <td>质控样</td>
                <td>{{data.table[index][5].specimen_value}}</td>
                <td>{{data.table[index][5].specimen_contrast_value}}</td>
                <td>{{data.table[index][5].specimen_error}}</td>
                <td>{{data.table[index][5].specimen_result}}</td>
              </tr>
              <tr>
                <td>水样1</td>
                <td>{{data.table[index][5].water_value_1}}</td>
                <td>{{data.table[index][5].water_contrast_value_1}}</td>
                <td>{{data.table[index][5].water_error_1}}</td>
                <td>{{data.table[index][5].water_result_1}}</td>
              </tr>
              <tr>
                <td>水样2</td>
                <td>{{data.table[index][5].water_value_2}}</td>
                <td>{{data.table[index][5].water_contrast_value_2}}</td>
                <td>{{data.table[index][5].water_error_2}}</td>
                <td>{{data.table[index][5].water_result_2}}</td>
              </tr>
              <tr>
                <td>水样3</td>
                <td>{{data.table[index][5].water_value_3}}</td>
                <td>{{data.table[index][5].water_contrast_value_3}}</td>
                <td>{{data.table[index][5].water_error_3}}</td>
                <td>{{data.table[index][5].water_result_3}}</td>
              </tr>
            </tbody>
          </table>
          <!-- 修改表格 -->
          <table v-show="newTable" border="1" rules="none" cellspacing="0" style="text-align: center;width: 800px;height:1020px;">
            <tbody>
              <tr>
                <td>运维代表</td>
                <td>{{data.equip.user_name}}</td>
                <td>企业代表</td>
                <td>{{data.equip.charge}}</td>
                <td>日期：{{data.equip.date}}</td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][0].device_name}}</span>
                  <input type="text" v-model="data.table[index][0].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][0].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][0].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][0].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][0].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][0].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][0].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][0].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][0].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][0].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][0].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][0].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][0].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][0].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][0].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][0].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][0].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][0].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][0].water_result_3">
                </td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][1].device_name}}</span>
                  <input type="text" v-model="data.table[index][1].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][1].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][1].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][1].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][1].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][1].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][1].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][1].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][1].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][1].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][1].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][1].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][1].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][1].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][1].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][1].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][1].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][1].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][1].water_result_3">
                </td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][2].device_name}}</span>
                  <input type="text" v-model="data.table[index][2].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][2].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][2].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][2].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][2].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][2].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][2].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][2].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][2].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][2].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][2].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][2].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][2].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][2].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][2].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][2].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][2].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][2].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][2].water_result_3">
                </td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][3].device_name}}</span>
                  <input type="text" v-model="data.table[index][3].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][3].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][3].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][3].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][3].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][3].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][3].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][3].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][3].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][3].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][3].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][3].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][3].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][3].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][3].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][3].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][3].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][3].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][3].water_result_3">
                </td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][4].device_name}}</span>
                  <input type="text" v-model="data.table[index][4].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][4].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][4].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][4].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][4].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][4].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][4].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][4].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][4].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][4].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][4].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][4].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][4].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][4].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][4].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][4].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][4].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][4].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][4].water_result_3">
                </td>
              </tr>
              <tr>
                <td>设备名称</td>
                <td rowspan="2">在线检测仪测定结果</td>
                <td rowspan="2">比对方法测定结果</td>
                <td rowspan="2">测定误差</td>
                <td rowspan="2">是否合格</td>
              </tr>
              <tr>
                <td class="td_cell">
                  <span>{{data.table[index][5].device_name}}</span>
                  <input type="text" v-model="data.table[index][5].device_name" style="width:96%;">
                </td>
              </tr>
              <tr>
                <td>质控样</td>
                <td class="td_cell">
                  <span>{{data.table[index][5].specimen_value}}</span>
                  <input type="text" v-model="data.table[index][5].specimen_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].specimen_contrast_value}}</span>
                  <input type="text" v-model="data.table[index][5].specimen_contrast_value">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].specimen_error}}</span>
                  <input type="text" v-model="data.table[index][5].specimen_error" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].specimen_result}}</span>
                  <input type="text" v-model="data.table[index][5].specimen_result">
                </td>
              </tr>
              <tr>
                <td>水样1</td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_value_1}}</span>
                  <input type="text" v-model="data.table[index][5].water_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_contrast_value_1}}</span>
                  <input type="text" v-model="data.table[index][5].water_contrast_value_1">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_error_1}}</span>
                  <input type="text" v-model="data.table[index][5].water_error_1" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_result_1}}</span>
                  <input type="text" v-model="data.table[index][5].water_result_1">
                </td>
              </tr>
              <tr>
                <td>水样2</td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_value_2}}</span>
                  <input type="text" v-model="data.table[index][5].water_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_contrast_value_2}}</span>
                  <input type="text" v-model="data.table[index][5].water_contrast_value_2">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_error_2}}</span>
                  <input type="text" v-model="data.table[index][5].water_error_2" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_result_2}}</span>
                  <input type="text" v-model="data.table[index][5].water_result_2">
                </td>
              </tr>
              <tr>
                <td>水样3</td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_value_3}}</span>
                  <input type="text" v-model="data.table[index][5].water_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_contrast_value_3}}</span>
                  <input type="text" v-model="data.table[index][5].water_contrast_value_3">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_error_3}}</span>
                  <input type="text" v-model="data.table[index][5].water_error_3" style="width:96%;">
                </td>
                <td class="td_cell">
                  <span>{{data.table[index][5].water_result_3}}</span>
                  <input type="text" v-model="data.table[index][5].water_result_3">
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="block">
      <template v-if="!read">
        <el-button type="primary" @click="createNewTable">修改表格</el-button>
        <el-button type="primary" @click="updateTable">保存修改</el-button>
      </template>
          <el-button type="primary" @click="printTable.id = 'table-content' + (index + 1)" v-print="printTable">导出pdf</el-button>
          <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
export default {
  props: ['data','name','read'],
  data() {
    return {
      activeName: '表1',
      oldTable: true,
      newTable: false,
      content: '',
      sum: 0,
      status: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback (vue) {
          $('title').text('水样对比记录表')
        },
        closeCallback (vue) {
          $('title').text('记录详情')
        }
      }
    }
  },
  mounted() {
    //数据处理
    this.sum = this.data.table.length;
    let rem = 6 - (this.data.table.length % 6);
    for (let i = 0; i < rem; i++) {
      const obj = {
        device_name: "",
        specimen_contrast_value: "",
        specimen_error: "",
        specimen_result: "",
        specimen_value: "",
        water_contrast_value_1: "",
        water_contrast_value_2: "",
        water_contrast_value_3: "",
        water_error_1: "",
        water_error_2: "",
        water_error_3: "",
        water_result_1: "",
        water_result_2: "",
        water_result_3: "",
        water_value_1: "",
        water_value_2: "",
        water_value_3: "",
      }
      this.data.table.push(obj);  
    }
    let a = [];
    let num = this.data.table.length / 6;
    for (let j = 0; j < num; j++) {
      let b = []
      for (let i = 0; i < 6; i++) {
        b[i] = this.data.table[j * 6 + i];      
      }
      a[j] = b;
    }
    this.data.table = a;
    this.status = true;
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data))
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      //新开辟一个内存地址，存储返回给后端的数据
      let tableList = JSON.parse(JSON.stringify(this.data.table));
      let arr = []
      for (let i = 0; i < tableList.length; i++) {
        for (let j = 0; j < tableList[i].length; j++) {
          arr.push(tableList[i][j])        
        }      
      }
      let data = arr.slice(0, this.sum)
      this.$emit('updateTable', data, val => {
        if (val === 'error') {
          this.data = this.saveData
        }
      })
    },
    // wordExport() {
    //   this.contenNum = '#table-content' + this.activeName.slice(1);
    //   let data = {
    //     fileName: "",                         //文档名
    //     fileType: ".doc",                     //文档类型
    //     isHeader: false,                      //是否显示页眉
    //     isFooter: false,                      //是否显示页脚
    //     header: "",                           //页眉标题
    //     styles: $("#wordExPortCss"),          //要导出的内容样式
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式
    //   }
    //   $(this.contenNum).wordExport(this.name,data);
    // }
  },
}
</script>
<style lang="less" scoped id="wordExPortCss">
td {
  border: 1px solid;
  padding: 5px;
  font-size: 14px;
}
.box-content{
  margin: 0 20px;
}
.block{
  margin: 20px;
}
.td_cell{
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 88%;
  caret-color: #426BF2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 210mm 297mm;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>