<template>
  <div style="margin: 0 20px;" v-if="status">
    <div id="table-content">
      <table
        style="width: 800px;height:10%;text-align: center;font-size: 13px;"
        cellspacing="0"
      >
        <tr>
          <td
            colspan="2"
            style="font-size: 20px; height: 40px;padding-left: 10px;letter-spacing:3px;border: 0;"
          >
            水污染源自动检测仪校准结果记录表
          </td>
        </tr>
      </table>

      <br />
      <table
        v-show="oldTable"
        id="oldTable"
        border="1"
        rules="none"
        cellspacing="0"
        style="text-align: center;width: 800px;height:1020px;"
      >
        <tbody>
          <tr>
            <td style="padding: 10px;width: 100px;">企业名称</td>
            <td colspan="2">{{ data.equip.company }}</td>
            <td>日期</td>
            <td style="width: 100px;">{{ data.equip.date }}</td>
            <td>时间</td>
            <td>{{ data.equip.time }}</td>
          </tr>
          <tr>
            <td rowspan="11">结果记录</td>
            <td style="width: 150px;">设备名称</td>
            <td style="width: 150px;">{{ data.table[0].dev_name }}</td>
            <td style="text-align: left;" colspan="4" rowspan="2">
              <p>校准变动记录：</p>
              <p>校准前：{{ data.table[0].dev_bef }}</p>
              <p>校准后：{{ data.table[0].dev_aft }}</p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td>{{ data.table[0].dev_model }}</td>
          </tr>
          <tr>
            <td>设备名称</td>
            <td>{{ data.table[1].dev_name }}</td>
            <td style="text-align: left;" colspan="4" rowspan="2">
              <p>校准变动记录：</p>
              <p>校准前：{{ data.table[1].dev_bef }}</p>
              <p>校准后：{{ data.table[1].dev_aft }}</p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td>{{ data.table[1].dev_model }}</td>
          </tr>

          <tr>
            <td>设备名称</td>
            <td>{{ data.table[2].dev_name }}</td>
            <td style="text-align: left;" colspan="4" rowspan="2">
              <p>校准变动记录：</p>
              <p>校准前：{{ data.table[2].dev_bef }}</p>
              <p>校准后：{{ data.table[2].dev_aft }}</p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td>{{ data.table[2].dev_model }}</td>
          </tr>

          <tr>
            <td>设备名称</td>
            <td>{{ data.table[3].dev_name }}</td>
            <td style="text-align: left;" colspan="4" rowspan="2">
              <p>校准变动记录：</p>
              <p>校准前：{{ data.table[3].dev_bef }}</p>
              <p>校准后：{{ data.table[3].dev_aft }}</p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td>{{ data.table[3].dev_model }}</td>
          </tr>

          <tr>
            <td>设备名称</td>
            <td>{{ data.table[4].dev_name }}</td>
            <td style="text-align: left;" colspan="4" rowspan="2">
              <p>校准变动记录：</p>
              <p>校准前：{{ data.table[4].dev_bef }}</p>
              <p>校准后：{{ data.table[4].dev_aft }}</p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td>
              {{
                data.table[4].dev_model != undefined
                  ? data.table[4].dev_model
                  : ""
              }}
            </td>
          </tr>

          <tr>
            <td style="text-align: left;padding: 20px;" colspan="6">
              运维代表签字：{{ data.equip.user_name }}
            </td>
          </tr>
          <tr>
            <td>校准确认</td>
            <td colspan="6">
              <p>企业代表签字：{{ data.equip.charge }}</p>
              <p style="text-align: right;padding: 0 20px 0 0;">
                {{ data.equip.date }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 修改表格 -->
      <table
        v-show="newTable"
        border="1"
        rules="none"
        cellspacing="0"
        style="text-align: center;width: 800px;height:1020px;"
      >
        <tbody>
          <tr>
            <td style="padding: 10px;width: 100px;">企业名称</td>
            <td colspan="2">{{ data.equip.company }}</td>
            <td>日期</td>
            <td style="width: 100px;">{{ data.equip.date }}</td>
            <td>时间</td>
            <td>{{ data.equip.time }}</td>
          </tr>
          <tr>
            <td rowspan="11">结果记录</td>
            <td style="width: 150px;">设备名称</td>
            <td style="width: 150px;" class="td_cell">
              <span>{{ data.table[0].dev_name }}</span>
              <input type="text" v-model="data.table[0].dev_name" />
            </td>
            <td
              style="text-align: left;"
              colspan="4"
              rowspan="2"
              class="td_cell"
            >
              <p>校准变动记录：</p>
              <p>
                校准前：
                <span>{{ data.table[0].dev_bef }}</span>
                <input
                  type="text"
                  v-model="data.table[0].dev_bef"
                  style="height:20%;top:60px;width:60%;left:80px;"
                />
              </p>
              <p>
                校准后：
                <span>{{ data.table[0].dev_aft }}</span>
                <input
                  type="text"
                  v-model="data.table[0].dev_aft"
                  style="height:20%;top:100px;width:60%;left:80px;"
                />
              </p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td class="td_cell">
              <span>{{ data.table[0].dev_model }}</span>
              <input type="text" v-model="data.table[0].dev_model" />
            </td>
          </tr>
          <tr>
            <td>设备名称</td>
            <td class="td_cell">
              <span>{{ data.table[1].dev_name }}</span>
              <input type="text" v-model="data.table[1].dev_name" />
            </td>
            <td
              style="text-align: left;"
              colspan="4"
              rowspan="2"
              class="td_cell"
            >
              <p>校准变动记录：</p>
              <p>
                校准前：
                <span>{{ data.table[1].dev_bef }}</span>
                <input
                  type="text"
                  v-model="data.table[1].dev_bef"
                  style="height:20%;top:60px;width:60%;left:80px;"
                />
              </p>
              <p>
                校准后：
                <span>{{ data.table[1].dev_aft }}</span>
                <input
                  type="text"
                  v-model="data.table[1].dev_aft"
                  style="height:20%;top:100px;width:60%;left:80px;"
                />
              </p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td class="td_cell">
              <span>{{ data.table[1].dev_model }}</span>
              <input type="text" v-model="data.table[1].dev_model" />
            </td>
          </tr>

          <tr>
            <td>设备名称</td>
            <td class="td_cell">
              <span>{{ data.table[2].dev_name }}</span>
              <input type="text" v-model="data.table[2].dev_name" />
            </td>
            <td
              style="text-align: left;"
              colspan="4"
              rowspan="2"
              class="td_cell"
            >
              <p>校准变动记录：</p>
              <p>
                校准前：
                <span>{{ data.table[2].dev_bef }}</span>
                <input
                  type="text"
                  v-model="data.table[2].dev_bef"
                  style="height:20%;top:60px;width:60%;left:80px;"
                />
              </p>
              <p>
                校准后：
                <span>{{ data.table[2].dev_aft }}</span>
                <input
                  type="text"
                  v-model="data.table[2].dev_aft"
                  style="height:20%;top:100px;width:60%;left:80px;"
                />
              </p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td class="td_cell">
              <span>{{ data.table[2].dev_model }}</span>
              <input type="text" v-model="data.table[2].dev_model" />
            </td>
          </tr>
          <tr>
            <td>设备名称</td>
            <td class="td_cell">
              <span>{{ data.table[3].dev_name }}</span>
              <input type="text" v-model="data.table[3].dev_name" />
            </td>
            <td
              style="text-align: left;"
              colspan="4"
              rowspan="2"
              class="td_cell"
            >
              <p>校准变动记录：</p>
              <p>
                校准前：
                <span>{{ data.table[3].dev_bef }}</span>
                <input
                  type="text"
                  v-model="data.table[3].dev_bef"
                  style="height:20%;top:60px;width:60%;left:80px;"
                />
              </p>
              <p>
                校准后：
                <span>{{ data.table[3].dev_aft }}</span>
                <input
                  type="text"
                  v-model="data.table[3].dev_aft"
                  style="height:20%;top:100px;width:60%;left:80px;"
                />
              </p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td class="td_cell">
              <span>{{ data.table[3].dev_model }}</span>
              <input type="text" v-model="data.table[3].dev_model" />
            </td>
          </tr>
          <tr>
            <td>设备名称</td>
            <td class="td_cell">
              <span>{{ data.table[4].dev_name }}</span>
              <input type="text" v-model="data.table[4].dev_name" />
            </td>
            <td
              style="text-align: left;"
              colspan="4"
              rowspan="2"
              class="td_cell"
            >
              <p>校准变动记录：</p>
              <p>
                校准前：
                <span>{{ data.table[4].dev_bef }}</span>
                <input
                  type="text"
                  v-model="data.table[4].dev_bef"
                  style="height:20%;top:60px;width:60%;left:80px;"
                />
              </p>
              <p>
                校准后：
                <span>{{ data.table[4].dev_aft }}</span>
                <input
                  type="text"
                  v-model="data.table[4].dev_aft"
                  style="height:20%;top:100px;width:60%;left:80px;"
                />
              </p>
            </td>
          </tr>
          <tr>
            <td>规格型号</td>
            <td class="td_cell">
              <span>{{ data.table[4].dev_model }}</span>
              <input type="text" v-model="data.table[4].dev_model" />
            </td>
          </tr>

          <tr>
            <td style="text-align: left;padding: 20px;" colspan="6">
              运维代表签字：{{ data.equip.user_name }}
            </td>
          </tr>
          <tr>
            <td>校准确认</td>
            <td colspan="6">
              <p>企业代表签字：{{ data.equip.charge }}</p>
              <p style="text-align: right;padding: 0 20px 0 0;">
                {{ data.equip.date }}
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="block">
      <template v-if="!read">
        <el-button type="primary" @click="createNewTable">修改表格</el-button>
        <el-button type="primary" @click="updateTable">保存修改</el-button>
      </template>
      <el-button type="primary" v-print="printTable">导出pdf</el-button>
      <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
    </div>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
export default {
  props: ["data", "name", "read"],
  data() {
    return {
      oldTable: true,
      newTable: false,
      sum: 0,
      status: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback(vue) {
          $("title").text("水质校准记录表");
        },
        closeCallback(vue) {
          $("title").text("记录详情");
        }
      }
    };
  },
  mounted() {
    this.sum = this.data.table.length;
    for (let i = this.data.table.length; i < 5; i++) {
      const obj = {
        dev_aft: "",
        dev_bef: "",
        dev_model: "-",
        dev_name: "-"
      };
      this.data.table.push(obj);
      this.status = true;
    }
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data));
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      let data = this.data.table.slice(0, this.sum);
      this.$emit("updateTable", data, val => {
        if (val === "error") {
          this.data = this.saveData;
        }
      });
    }
    // wordExport() {
    //   let data = {
    //     fileName: "",                         //文档名
    //     fileType: ".doc",                     //文档类型
    //     isHeader: false,                      //是否显示页眉
    //     isFooter: false,                      //是否显示页脚
    //     header: "",                           //页眉标题
    //     styles: $("#wordExPortCss"),          //要导出的内容样式
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式
    //   }
    //   $('#table-content').wordExport(this.name,data);
    // }
  }
};
</script>
<style lang="less" scoped id="wordExPortCss">
p {
  text-align: left;
  padding: 0 0 0 15px;
}
td {
  border: 1px solid;
  padding: 5px;
  height: 22px;
}
.block {
  margin: 20px;
}
.td_cell {
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 97%;
  height: 97%;
  caret-color: #426bf2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 210mm 297mm;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>
