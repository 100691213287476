<template>
  <div style="margin: 0 20px;">
    <div id="table-content">
      <table class="content content_head" cellspacing="0" border="0">
        <tr>
          <td colspan="2" class="content_title">
            水污染源自动监测设备运营维护日常巡查表
          </td>
        </tr>
        <tr>
          <td colspan="2" style="font-size:14px;height:20px;border:0;">
            <p class="content_title_left">
              <span>企业名称：{{ data.equip.company }}</span>
              <span class="show">&nbsp;&nbsp;&nbsp;</span>
              <span class="content_title_right"
                >维护单位：{{ data.equip.uphold_name }}</span
              >
            </p>
            <p class="content_title_left">
              <span>设备名称：{{ data.equip.device_name }}</span>
              <span class="show"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span
              >
              <span class="content_title_right"
                >设备型号：{{ data.equip.device_model }}</span
              >
            </p>
          </td>
        </tr>
      </table>

      <table
        v-if="oldTable"
        id="oldTable"
        border="1"
        rules="none"
        cellspacing="0"
        class="content content_body"
      >
        <tbody>
          <tr>
            <!-- 15 -->
            <td rowspan="15" class="td_wid">日常维护记录</td>
            <!-- 2 -->
            <td rowspan="2" class="td_wid">维护设备</td>
            <td>查询日志</td>
            <td class="td_wid">{{ data.table[0].query_log }}</td>
          </tr>
          <tr>
            <td>试剂、耗材是否充足</td>
            <td>{{ data.table[0].material_ready }}</td>
          </tr>
          <!-- 4 -->
          <tr>
            <td rowspan="4">系统检查</td>
            <td>供电系统(稳压、UPS等)</td>
            <td>{{ data.table[0].power_system }}</td>
          </tr>
          <tr>
            <td>通讯系统(本地通讯、远程通讯)</td>
            <td>{{ data.table[0].communication_system }}</td>
          </tr>
          <tr>
            <td>控制系统(PLC、工控机等)</td>
            <td>{{ data.table[0].control_system }}</td>
          </tr>
          <tr>
            <td>采水系统</td>
            <td>{{ data.table[0].water_extraction_system }}</td>
          </tr>
          <!-- 3 -->
          <tr>
            <td rowspan="3">仪器检查</td>
            <td>仪器显示、关键参数等</td>
            <td>{{ data.table[0].instrument_show }}</td>
          </tr>
          <tr>
            <td>故障报警</td>
            <td>{{ data.table[0].fault_alarm }}</td>
          </tr>
          <tr>
            <td>仪器采样、计量、反应、加热、检测</td>
            <td>{{ data.table[0].instrument_piping }}</td>
          </tr>
          <!-- 6 -->
          <tr>
            <td rowspan="6">周期维护</td>
            <td>采水管路清洗</td>
            <td>{{ data.table[0].instrument_clean }}</td>
          </tr>
          <tr>
            <td>仪器管路清洗</td>
            <td>{{ data.table[0].piping_clean }}</td>
          </tr>
          <tr>
            <td>试剂更换</td>
            <td>{{ data.table[0].reagent_replace }}</td>
          </tr>
          <tr>
            <td>耗材更换</td>
            <td>{{ data.table[0].part_replace }}</td>
          </tr>
          <tr>
            <td>上传数据和现场数据的一致性</td>
            <td>{{ data.table[0].site_log }}</td>
          </tr>
          <tr>
            <td>站房卫生</td>
            <td>{{ data.table[0].hygiene_clean }}</td>
          </tr>
          <tr>
            <td>其他情况</td>
            <td colspan="3" style="text-align: left;padding: 0 15px 0 15px;">
              {{ data.table[0].other_desc }}
            </td>
          </tr>
          <tr>
            <td>维护记录</td>
            <td colspan="3">{{ data.table[0].remark }}</td>
          </tr>
          <tr>
            <td>企业代表</td>
            <td>{{ data.equip.charge }}</td>
            <td>运维代表</td>
            <td>{{ data.equip.user_name }}</td>
          </tr>
          <tr>
            <td>服务耗时</td>
            <td>{{ data.table[0].use_time }}</td>
            <td>日期</td>
            <td>{{ data.equip.date }}</td>
          </tr>
        </tbody>
      </table>
      <!-- 修改表格 -->
      <table
        v-if="newTable"
        border="1"
        rules="none"
        cellspacing="0"
        style="text-align: center;width:800px;height:1020px;"
      >
        <tbody>
          <tr>
            <td rowspan="15" style="width:150px;">日常维护记录</td>
            <td rowspan="2" style="width:150px;">维护设备</td>
            <td style="width:150px;">查询日志</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].query_log"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].query_log"
              />异常
            </td>
          </tr>
          <tr>
            <td>试剂、耗材是否充足</td>
            <td>
              <input
                type="radio"
                value="充足"
                v-model="data.table[0].material_ready"
              />充足
              <input
                type="radio"
                value="不足"
                v-model="data.table[0].material_ready"
              />不足
            </td>
          </tr>
          <tr>
            <td rowspan="4">系统检查</td>
            <td>供电系统(稳压、UPS等)</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].power_system"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].power_system"
              />异常
            </td>
          </tr>
          <tr>
            <td>通讯系统(本地通讯、远程通讯)</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].communication_system"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].communication_system"
              />异常
            </td>
          </tr>
          <tr>
            <td>控制系统(PLC、工控机等)</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].control_system"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].control_system"
              />异常
            </td>
          </tr>
          <tr>
            <td>采水系统</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].water_extraction_system"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].water_extraction_system"
              />异常
            </td>
          </tr>
          <tr>
            <td rowspan="3">仪器检查</td>
            <td>仪器显示、关键参数等</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].instrument_show"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].instrument_show"
              />异常
            </td>
          </tr>
          <tr>
            <td>故障报警</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].fault_alarm"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].fault_alarm"
              />异常
            </td>
          </tr>
          <tr>
            <td>仪器采样、计量、反应、加热、检测</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].instrument_piping"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].instrument_piping"
              />异常
            </td>
          </tr>
          <tr>
            <td rowspan="6">周期维护</td>
            <td>采水管路清洗</td>
            <td>
              <input
                type="radio"
                value="清洗"
                v-model="data.table[0].instrument_clean"
              />清洗
              <input
                type="radio"
                value="未清洗"
                v-model="data.table[0].instrument_clean"
              />未清洗
            </td>
          </tr>
          <tr>
            <td>仪器管路清洗</td>
            <td>
              <input
                type="radio"
                value="清洗"
                v-model="data.table[0].piping_clean"
              />清洗
              <input
                type="radio"
                value="未清洗"
                v-model="data.table[0].piping_clean"
              />未清洗
            </td>
          </tr>
          <tr>
            <td>试剂更换</td>
            <td>
              <input
                type="radio"
                value="已更换"
                v-model="data.table[0].reagent_replace"
              />已更换
              <input
                type="radio"
                value="未更换"
                v-model="data.table[0].reagent_replace"
              />未更换
            </td>
          </tr>
          <tr>
            <td>耗材更换</td>
            <td>
              <input
                type="radio"
                value="已更换"
                v-model="data.table[0].part_replace"
              />已更换
              <input
                type="radio"
                value="未更换"
                v-model="data.table[0].part_replace"
              />未更换
            </td>
          </tr>
          <tr>
            <td>上传数据和现场数据的一致性</td>
            <td>
              <input
                type="radio"
                value="正常"
                v-model="data.table[0].site_log"
              />正常
              <input
                type="radio"
                value="异常"
                v-model="data.table[0].site_log"
              />异常
            </td>
          </tr>
          <tr>
            <td>站房卫生</td>
            <td>
              <input
                type="radio"
                value="打扫"
                v-model="data.table[0].hygiene_clean"
              />已打扫
              <input
                type="radio"
                value="未打扫"
                v-model="data.table[0].hygiene_clean"
              />未打扫
            </td>
          </tr>
          <tr>
            <td>其他情况</td>
            <td
              colspan="3"
              class="td_cell"
              style="text-align: left;padding: 0 15px 0 15px;"
            >
              <span>{{ data.table[0].other_desc }}</span>
              <input type="text" v-model="data.table[0].other_desc" />
            </td>
          </tr>
          <tr>
            <td>维护记录</td>
            <td colspan="3" class="td_cell">
              <span>{{ data.table[0].remark }}</span>
              <input type="text" v-model="data.table[0].remark" />
            </td>
          </tr>
          <tr>
            <td>企业代表</td>
            <td>{{ data.equip.charge }}</td>
            <td>运维代表</td>
            <td>{{ data.equip.user_name }}</td>
          </tr>
          <tr>
            <td>服务耗时</td>
            <td class="td_cell">
              <span>{{ data.table[0].use_time }}</span>
              <input
                type="text"
                v-model="data.table[0].use_time"
                style="width: 97%;"
              />
            </td>
            <td>日期</td>
            <td>{{ data.equip.date }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="block">
      <template v-if="!read">
        <el-button type="primary" @click="createNewTable">修改表格</el-button>
        <el-button type="primary" @click="updateTable">保存修改</el-button>
      </template>
      <el-button type="primary" v-print="printTable">导出pdf</el-button>
      <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { saveAs } from "file-saver";
import "./wordexport";
export default {
  props: ["data", "name", "detail", "read"],
  data() {
    return {
      oldTable: true,
      newTable: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback(vue) {
          $("title").text("水质巡检记录表");
        },
        closeCallback(vue) {
          $("title").text("记录详情");
        }
      }
    };
  },
  computed: mapState({
    url: state => state.url
  }),
  methods: {
    ...mapActions(["pageInit"]),
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data));
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      this.$emit("updateTable", this.data.table, val => {
        if (val === "error") {
          this.data = this.saveData;
        }
      });
    }
    // wordExport() {
    //   //设置 word table表格的样式
    //   let style = '.content { width: 756px; text-align: center;} .content_head { height:10%; font-size: 13px; border: 0;} .content_title { width: 692px; font-size: 20px; height: 40px; margin-left: 145px; letter-spacing:3px;border: 0; } .content_title_left { margin-left: 20px; } .show { display: inline-block;} .content_title_right { margin-left: 50px; } .content_body { height:800px; } .td_wid { width:141px; } td { padding: 5px; height: 22px; } span { display: inline-block; }'
    //   //导出word
    //   $('#table-content').wordExport(this.name, style); //文件名， 样式
    // }
  }
};
</script>
<style lang="less" scoped>
.content {
  width: 800px;
  text-align: center;
}
.content_head {
  height: 10%;
  font-size: 13px;
}
.content_title {
  font-size: 20px;
  height: 40px;
  padding-left: 10px;
  letter-spacing: 3px;
  border: 0;
}
.content_title_left {
  text-align: left;
  margin-left: 20px;
}
.content_title_right {
  float: right;
  margin-right: 20px;
}
.show {
  display: none;
}
.content_body {
  height: 1020px;
}
.td_wid {
  width: 150px;
}
td {
  border: 1px solid;
  padding: 5px;
  height: 22px;
}
.block {
  margin: 20px;
}
.td_cell {
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 99.4%;
  height: 95%;
  caret-color: #426bf2;
  border-style: none;
}
</style>
