<template>
  <div style="margin: 0 20px;">
    <div class="box-content">
      <el-tabs v-model="activeName">
        <el-tab-pane
          v-for="(item, index) in data.table"
          :key="index"
          :label="'表' + (index + 1)"
          :name="'表' + (index + 1)"
        >
          <div :id="'table-content' + (index + 1)">
            <table
              style="width: 800px;height:10%;text-align: center;font-size: 13px;"
              cellspacing="0"
            >
              <tr>
                <td
                  colspan="2"
                  style="font-size: 20px; height: 40px;padding-left: 10px;letter-spacing:3px;border: 0;"
                >
                  故障维修记录
                </td>
              </tr>
            </table>

            <table
              v-show="oldTable"
              :id="'table' + (index + 1)"
              border="1"
              rules="none"
              cellspacing="0"
              style="text-align: center;width: 800px;height:1020px;"
            >
              <tbody>
                <tr>
                  <td>设备名称</td>
                  <td>{{ item.device_name }}</td>
                  <td>设备型号</td>
                  <td>{{ item.device_model }}</td>
                  <td>设备编号</td>
                  <td>{{ item.device_number }}</td>
                </tr>
                <tr>
                  <td>运维单位</td>
                  <td colspan="3">{{ data.equip.uphold_name }}</td>
                  <td>运维代表</td>
                  <td>{{ data.equip.user_name }}</td>
                </tr>
                <tr>
                  <td>发生时间</td>
                  <td colspan="5">{{ item.fault_time }}</td>
                </tr>
                <tr>
                  <td>故障原因</td>
                  <td colspan="5">{{ item.fault_reason }}</td>
                </tr>
                <tr>
                  <td>故障类型</td>
                  <td colspan="5">{{ item.fault_type }}</td>
                </tr>
                <tr>
                  <td>处理过程</td>
                  <td colspan="5">{{ item.fault_process }}</td>
                </tr>
                <tr>
                  <td>处理结果</td>
                  <td colspan="5">{{ item.fault_result }}</td>
                </tr>
                <tr>
                  <td>恢复时间</td>
                  <td colspan="5">{{ item.fault_recover_time }}</td>
                </tr>
                <tr>
                  <td>备注</td>
                  <td colspan="5">{{ item.remark }}</td>
                </tr>
                <tr>
                  <td>企业代表</td>
                  <td colspan="2">{{ data.equip.charge }}</td>
                  <td>离站时间</td>
                  <td colspan="2">{{ data.equip.date }}</td>
                </tr>
              </tbody>
            </table>
            <!-- 修改表格 -->
            <table
              v-show="newTable"
              border="1"
              rules="none"
              cellspacing="0"
              style="text-align: center;width: 800px;height:1020px;"
            >
              <tbody>
                <tr>
                  <td>设备名称</td>
                  <td class="td_cell">
                    <span>{{ dataList.table[index].device_name }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].device_name"
                      style="width:96%;"
                    />
                  </td>
                  <td>设备型号</td>
                  <td class="td_cell">
                    <span>{{ dataList.table[index].device_model }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].device_model"
                      style="width:97%;"
                    />
                  </td>
                  <td>设备编号</td>
                  <td class="td_cell">
                    <span>{{ dataList.table[index].device_number }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].device_number"
                      style="width:96%;"
                    />
                  </td>
                </tr>
                <tr>
                  <td>运维单位</td>
                  <td colspan="3">{{ dataList.equip.uphold_name }}</td>
                  <td>运维代表</td>
                  <td>{{ dataList.equip.user_name }}</td>
                </tr>
                <tr>
                  <td>发生时间</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_time }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_time"
                    />
                  </td>
                </tr>
                <tr>
                  <td>故障原因</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_reason }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_reason"
                    />
                  </td>
                </tr>
                <tr>
                  <td>故障类型</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_type }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_type"
                    />
                  </td>
                </tr>
                <tr>
                  <td>处理过程</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_process }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_process"
                    />
                  </td>
                </tr>
                <tr>
                  <td>处理结果</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_result }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_result"
                    />
                  </td>
                </tr>
                <tr>
                  <td>恢复时间</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].fault_recover_time }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].fault_recover_time"
                    />
                  </td>
                </tr>
                <tr>
                  <td>签退时间</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].leave_time }}</span>
                    <input
                      type="text"
                      v-model="dataList.table[index].leave_time"
                    />
                  </td>
                </tr>
                <tr>
                  <td>备注</td>
                  <td colspan="5" class="td_cell">
                    <span>{{ dataList.table[index].remark }}</span>
                    <input type="text" v-model="dataList.table[index].remark" />
                  </td>
                </tr>
                <tr>
                  <td>企业代表</td>
                  <td colspan="2">{{ dataList.equip.charge }}</td>
                  <td>离站时间</td>
                  <td colspan="2">{{ dataList.equip.date }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="block">
            <template v-if="!read">
              <el-button type="primary" @click="createNewTable"
                >修改表格</el-button
              >
              <el-button type="primary" @click="updateTable"
                >保存修改</el-button
              >
            </template>
            <el-button
              type="primary"
              @click="printTable.id = 'table-content' + (index + 1)"
              v-print="printTable"
              >导出pdf</el-button
            >
            <!-- <el-button type="primary" @click="wordExport">导出word</el-button> -->
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
// import { saveAs } from 'file-saver';
// import './wordexport';
export default {
  props: ["data", "name", "read"],
  data() {
    return {
      activeName: "表1",
      dataList: this.data,
      oldTable: true,
      newTable: false,
      saveData: null,
      printTable: {
        id: "table-content",
        preview: false,
        beforeOpenCallback(vue) {
          $("title").text("维修记录表");
        },
        closeCallback(vue) {
          $("title").text("记录详情");
        }
      }
    };
  },
  methods: {
    createNewTable() {
      this.saveData = JSON.parse(JSON.stringify(this.data));
      this.oldTable = false;
      this.newTable = true;
    },
    updateTable() {
      this.oldTable = true;
      this.newTable = false;
      this.$emit("updateTable", this.dataList.table, val => {
        if (val === "error") {
          this.data = this.saveData;
        }
      });
    }
    // wordExport() {
    //   this.contenNum = '#table-content' + this.activeName.slice(1);
    //   let data = {
    //     fileName: "",                         //文档名
    //     fileType: ".doc",                     //文档类型
    //     isHeader: false,                      //是否显示页眉
    //     isFooter: false,                      //是否显示页脚
    //     header: "",                           //页眉标题
    //     styles: $("#wordExPortCss"),          //要导出的内容样式
    //     updateExportCss: $("#updateExportCss")//需要修改导出的word样式
    //   }
    //   $(this.contenNum).wordExport(this.name,data);
    // }
  }
};
</script>
<style lang="less" scoped id="wordExPortCss">
td {
  border: 1px solid;
  padding: 5px;
  height: 22px;
}
.box-content {
  margin: 0 20px;
}
.block {
  margin: 20px;
}
.td_cell {
  position: relative;
}
span {
  display: inline-block;
}
.td_cell input {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  width: 99.3%;
  height: 97%;
  caret-color: #426bf2;
  border-style: none;
}
</style>
<style type="text/css" id="updateExportCss">
@page WordSection {
  size: 210mm 297mm;
  /*宽高*/
  margin: 30px;
  /*边距*/
}
</style>
